import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FlagProvider } from '@unleash/proxy-client-react';

const config = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: process.env.REACT_APP_UNLEASH_REFRESH_INTERVAL ? process.env.REACT_APP_UNLEASH_REFRESH_INTERVAL : 10,
  appName: 'mandiri-flight-web-app',
  environment: process.env.REACT_APP_UNLEASH_ENVIRONMENT,
};

ReactDOM.render(
  <React.StrictMode>
    <FlagProvider config={config}>
      <App />
    </FlagProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
