/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { Accordion, Select, Checkbox, MenuItem, Grid, InputLabel, AccordionDetails, AccordionSummary, Button, Divider, IconButton, Paper, Radio, Slide, TextField, Typography, FormHelperText } from '@mui/material';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import { FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { countryData } from '../../common/Country';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';


import 'moment/locale/id'
moment.locale('id');
import { id as indonesian } from 'date-fns/locale'; // this is localization for Indonesian Language......
import { isDateBeforeSixMonths, validateAge, INDONESIA_CODE } from '../../common/Utils';

const PassengerInfo = ({ ticiketInfo: { adultPassengers, childPassengers, totalPassengers, infantPassengers}, 
    submitError, initialFormData, documentType, docRequireForDomestic, resetBaggageSelectionData
    }) => {

    const classes = useStyle();
    const [userID, submitUserID] = useState(''); // set user id on click of submit button in Slider
    const [userIndex, setUserIndex] = useState(''); // gets the user index from list array on click on Jenis ID

    const [list, setList] = useState([]);
    const [isDobOpen, setDobOpen] = useState(false);
    const [isPassportExpOpen, setPassportExpOpen] = useState(false);

    const [titleFocus, setTitleFocus] = useState(false)
    const [firstNameFocus, setFirstNameFocus] = useState(false)
    const [lastNameFocus, setLastNameFocus] = useState(false)
    const [citizenshipFocus, setCitizenshipFocus] = useState(false)
    const [nomorIdFocus, setNomorIdFocus] = useState(false)
    const [passportNumFocus, setPassportNumFocus] = useState(false)
    const [passportIssueFocus, setPassportIssueFocus] = useState(false)
    const [dobFocus, setDobFocus] = useState(false)
    const [passportExpirationFocus, setPassportExpirationFocus] = useState(false)
    const [passengerDocumentType, setPassengerDocumentType] = useState(documentType);

    useEffect(() => {
        if (totalPassengers > 0) {
            let temp = [];
            let j = adultPassengers, k = childPassengers, z = infantPassengers;

            let KAI = JSON.parse(localStorage.getItem("KAI"));
            if (KAI?.passengerList?.length) {
                temp = [...KAI.passengerList]
                for (let i = 0; i < temp.length; i++) {
                    temp[i].expand = false;
                    temp[i].containerError = false;
                    temp[i].ageError = "";
                }
                KAI.passengerList = temp;
                localStorage.setItem("KAI", JSON.stringify(KAI));
            }
            else {
                for (let i = 0; i < totalPassengers;) {
                    if (j > 0) {
                        temp[i] = {
                            passenger: "adult", salutation: '', pFirstName: '', lastName: '', noLastName: false,
                            dateOfBirth: '', citizenship: '', numberID: "", id: i, expand: false, passportNo: '',
                            passportIssuingCountry: '', passportExpirationDate: '', ageError: '', containerError: false, passengerDocumentType: documentType,
                        };
                        j--;
                        i++;
                    }
                    if (j == 0 && k > 0) {
                        temp[i] = {
                            passenger: "child", salutation: '', pFirstName: '', lastName: '', noLastName: false,
                            dateOfBirth: '', citizenship: '', numberID: "", id: i, expand: false, passportNo: '',
                            passportIssuingCountry: '', passportExpirationDate: '', ageError: '', containerError: false, passengerDocumentType: documentType,
                        };
                        k--;
                        i++;
                    }
                    if (j == 0 && k == 0 && z > 0) {
                        temp[i] = {
                            passenger: "infant", salutation: '', pFirstName: '', lastName: '', noLastName: false,
                            dateOfBirth: '', citizenship: '', numberID: "", id: i, expand: false, passportNo: '',
                            passportIssuingCountry: '', passportExpirationDate: '', ageError: '', containerError: false, passengerDocumentType: documentType,
                        };
                        z--;
                        i++;
                    }
                }
                if (KAI) {
                    KAI.passengerList = [...temp];
                } else {
                    KAI = { passengerList: [...temp] }
                }

                localStorage.setItem("KAI", JSON.stringify(KAI));
            }
            setList(temp);
        }
    }, [adultPassengers, childPassengers, totalPassengers, infantPassengers]);

    const [salutation, setSalutation] = useState("");
    const [pFirstName, setFirstName] = useState("");
    const [noLastName, setNoLastName] = useState(false);
    const [lastName, setLastName] = useState("");
    const [citizenship, setCitizenship] = useState("ID");
    const [passengerNumberID, setPassengerNumberID] = useState("");
    const [passportNo, setPassportNo] = useState("");
    const [passportIssuingCountry, setPassportIssuingCountry] = useState("");
    const [error, setError] = useState(false);
    const [dateOfBirth, setDateofBirth] = useState(null);
    const [passportExpirationDate, setPassportExpirationDate] = useState("");
    
    const handleAccordion = (i) => {
        setUserIndex(i);
        let p = new Promise((resolve, reject) => {
            resolve(JSON.parse(localStorage.getItem("KAI")))
        })
        p.then((res) => {
            let KAI = res;
            if (KAI.passengerList.length === 0) {
                let temp = [];
                let j = adultPassengers, k = childPassengers, z = infantPassengers;

                let KAI = JSON.parse(localStorage.getItem("KAI"));
                if (KAI?.passengerList?.length) {
                    temp = [...KAI.passengerList]
                    for (let i = 0; i < temp.length; i++) {
                        temp[i].expand = false;
                        temp[i].ageError = "";
                    }
                    KAI.passengerList = temp;
                    localStorage.setItem("KAI", JSON.stringify(KAI));
                }
                else {
                    for (let i = 0; i < totalPassengers;) {
                        if (j > 0) {
                            temp[i] = {
                                passenger: "adult", salutation: '', pFirstName: '', lastName: '', noLastName: false,
                                dateOfBirth: '', citizenship: '', numberID: "", id: i, expand: false, passportNo: '',
                                passportIssuingCountry: '', passportExpirationDate: '', ageError: '', containerError: false, passengerDocumentType: documentType,
                            };
                            j--;
                            i++;
                        }
                        if (j == 0 && k > 0) {
                            temp[i] = {
                                passenger: "child", salutation: '', pFirstName: '', lastName: '', noLastName: false,
                                dateOfBirth: '', citizenship: '', numberID: "", id: i, expand: false, passportNo: '',
                                passportIssuingCountry: '', passportExpirationDate: '', ageError: '', containerError: false, passengerDocumentType: documentType,
                            };
                            k--;
                            i++;
                        }
                        if (j == 0 && k == 0 && z > 0) {
                            temp[i] = {
                                passenger: "infant", salutation: '', pFirstName: '', lastName: '', noLastName: false,
                                dateOfBirth: '', citizenship: '', numberID: "", id: i, expand: false, passportNo: '',
                                passportIssuingCountry: '', passportExpirationDate: '', ageError: '', containerError: false, passengerDocumentType: documentType,
                            };
                            z--;
                            i++;
                        }
                    }
                    if (KAI) {
                        KAI.passengerList = [...temp];
                    } else {
                        KAI = { passengerList: [...temp] }
                    }

                    localStorage.setItem("KAI", JSON.stringify(KAI));
                }
                setList(temp);
            }
            let list = [...KAI.passengerList];
            for (let index = 0; index < list.length; index++) {
                if (index == i) {
                    list[index].expand = !(list[index].expand);
                    setSalutation(list[i].salutation);
                    setFirstName(list[i].pFirstName);
                    setNoLastName(list[i].noLastName);
                    if (moment(list[i].dateOfBirth).isValid()) {
                        setDateofBirth(list[i].dateOfBirth);
                    } else {
                        setDateofBirth(null);
                    }
                    setCitizenship(list[i].citizenship || "ID");
                    setLastName(list[i]?.lastName);
                    setPassengerNumberID(list[i]?.numberID);
                    setPassportNo(list[i]?.passportNo);
                    setPassportIssuingCountry(list[i]?.passportIssuingCountry);
                    setPassengerDocumentType(list[i]?.passengerDocumentType || documentType);
                    if (moment(list[i].passportExpirationDate).isValid()) {
                        setPassportExpirationDate(list[i].passportExpirationDate);
                    } else {
                        setPassportExpirationDate("");
                    }
                    if (list[index].containerError) {
                        setError(true);
                    } else { 
                        setError(false);
                    }
                }
                else list[index].expand = false;
            }
            // setError(false)
            KAI.passengerList = [...list];
            localStorage.setItem("KAI", JSON.stringify(KAI));
            setList(list)
        })
    }

    const validateNumberId = (p) => {
        let isValidated = true;
        if (passengerDocumentType === "NODOC") {
            return true;
        }
        if (p) {
            if (passengerDocumentType === "NATIONAL_ID") {
                isValidated = p.numberID !== "" && p.numberID.length == 16;
            } else if (passengerDocumentType === "PASSPORT") {
                isValidated = p.passportNo !== "" && p.passportNo.length == 10 && p.passportIssuingCountry !== "" && p.passportExpirationDate !== "" && !isDateBeforeSixMonths(passportExpirationDate, initialFormData.depDate);
            }
        } else {
            if (passengerDocumentType === "NATIONAL_ID") {
                isValidated = passengerNumberID !== "" && passengerNumberID.length == 16;
            } else if (passengerDocumentType === "PASSPORT") {
                isValidated = passportNo !== "" && passportNo.length >=7 && passportNo.length <= 10 && passportIssuingCountry !== "" && passportExpirationDate !== "" && !isDateBeforeSixMonths(passportExpirationDate, initialFormData.depDate);
            }
        }

        return isValidated;
    }

    const checkAgeValidation = (dateOfBirth) => {
        let ageError = validateAge(list, userIndex, initialFormData, dateOfBirth);
        let p = new Promise((resolve, reject) => {
            resolve(JSON.parse(localStorage.getItem("KAI")))
        })
        p.then((res) => {
            let KAI = res;
            let list = [...KAI.passengerList]
            list[userIndex].ageError = ageError;
            setList(list);
            KAI.passengerList = [...list];
            localStorage.setItem("KAI", JSON.stringify(KAI));
        })
    }

    // after clicking Submit button.
    const handleSubmitPassengerInfo = (passenger) => {
        let expand;
        let containerError;
        let ageError = validateAge(list, userIndex, initialFormData, dateOfBirth);
        if (pFirstName.length > 1 && (lastName.length > 1 || noLastName) && salutation !== ''
            && dateOfBirth !== '' && dateOfBirth !== null && citizenship !== '' && validateNumberId() && !ageError) {
            setError(false);
            expand = false;
            containerError = false
        }
        else {
            setError(true);
            expand = true;
        }

        let p = new Promise((resolve, reject) => {
            resolve(JSON.parse(localStorage.getItem("KAI")))
        })
        p.then((res) => {
            let KAI = res;
            let list = [...KAI.passengerList]
            list[userIndex].expand = expand;
            list[userIndex].containerError = containerError;
            list[userIndex].salutation = salutation;
            list[userIndex].pFirstName = pFirstName;
            list[userIndex].lastName = lastName;
            list[userIndex].noLastName = noLastName;
            list[userIndex].dateOfBirth = dateOfBirth;
            list[userIndex].citizenship = citizenship;
            list[userIndex].numberID = passengerNumberID;
            list[userIndex].passportNo = passportNo;
            list[userIndex].passportIssuingCountry = passportIssuingCountry;
            list[userIndex].passportExpirationDate = passportExpirationDate;
            list[userIndex].ageError = ageError;
            list[userIndex].passengerDocumentType = passengerDocumentType;
            setList(list);
            // setError(false);
            KAI.passengerList = [...list];
            localStorage.setItem("KAI", JSON.stringify(KAI));
            resetBaggageSelectionData();
        })

    }

    useEffect(() => {

        if (submitError) {
            let p = new Promise((resolve, reject) => resolve(JSON.parse(localStorage.getItem("KAI"))));
            p.then(res => {
                let KAI = res;

                if (KAI?.passengerList?.length) {
                    let passengerList = KAI.passengerList;
                    let expandFlag = false;
                    for (let i = 0; i < passengerList.length; i++) {
                        if (passengerList[i].salutation === "" || passengerList[i].pFirstName.length < 2 ||
                            (passengerList[i].lastName.length < 2 && !passengerList[i].noLastName) ||
                            passengerList[i].dateOfBirth === "" || passengerList[i].dateOfBirth === null || passengerList[i].citizenship === "" ||
                            !validateNumberId(passengerList[i])) {
                            passengerList[i].expand = !expandFlag ? true : false;
                            expandFlag = true;   
                            passengerList[i].containerError = true;
                            setError(true);
                            setPassengerNumberID(passengerList[i].numberID);
                            setSalutation(passengerList[i].salutation);
                            setFirstName(passengerList[i].pFirstName);
                            setLastName(passengerList[i].lastName);
                            setNoLastName(passengerList[i].noLastName);
                            setPassengerDocumentType(passengerList[i]?.passengerDocumentType || documentType);
                            if (moment(list[i].dateOfBirth).isValid()) {
                                setDateofBirth(passengerList[i].dateOfBirth);
                            } else {
                                setDateofBirth(null);
                            }
                            if (moment(list[i].passportExpirationDate).isValid()) {
                                setPassportExpirationDate(passengerList[i].passportExpirationDate);
                            } else {
                                setPassportExpirationDate("");
                            }
                            setCitizenship(passengerList[i].citizenship || "ID")
                            setUserIndex(i)
                        }
                        else {
                            passengerList[i].expand = false;
                        }
                    }
                    setList(passengerList);
                    KAI.passengerList = [...passengerList];
                    localStorage.setItem("KAI", JSON.stringify(KAI))
                }
            })
        }
    }, [submitError]);

    useEffect(() => {
        if (userID && list.length) {
            let p = new Promise((resolve, reject) => {
                resolve(JSON.parse(localStorage.getItem("KAI")))
            })
            p.then(res => {
                let KAI = res;
                KAI.passengerList[userIndex].numberID = "";
                setList(KAI.passengerList);
                localStorage.setItem("KAI", JSON.stringify(KAI));
                submitUserID("");
                setPassengerNumberID("");
            })
        }
    }, [userID]);
    const getPassportDateError = () => {
        if (
          (passportExpirationDate === "" || passportExpirationDate === null) &&
          error
        ) {
          return "Tanggal Habis Berlaku tidak boleh kosong";
        }
    
        if (isDateBeforeSixMonths(passportExpirationDate, initialFormData.depDate)) {
          return "Masa berlaku passport tidak boleh kurang dari 6 bulan";
        }
        return ""
    };

    const onChangeNationality = (e) => {
        if(initialFormData.arrivalAirportDetails.countryCode === initialFormData.departureAirportDetails.countryCode) {
            if(e.target.value !== INDONESIA_CODE){
                if(docRequireForDomestic){
                    setPassengerDocumentType("PASSPORT");
                } else {
                    setPassengerDocumentType("NODOC");
                }
            }else{
                setPassengerDocumentType(documentType);
            }
        }
        setCitizenship(e.target.value);
    
    }
    
    return (
        <div id="slidercomponent">
            <div style={{ marginTop: "24px" }}>
                <Typography children={"Data Penumpang"} className={"inputSectionLabels"} />
            </div>
            {/* passenger => (adult/child) | passengerID => (KTP/Paspor) */}
            {list.map(({ passenger, passengerID, numberID, id, ageError: passengerAgeError, expand, passportNo: submitedPassportNumber, lastName: submitedLastName, pFirstName: submitedFirstName, passengerDocumentType: currentPaxDoc, containerError }, i) =>
                { 
                return (<div key={i} style={{ marginTop: "16px" }}>
                    <Accordion id={`${passenger}-passengerInfo-${i}`} expanded={expand} onChange={() => handleAccordion(i)} className={containerError ? "dropShadowError" : "dropShadow"} /*style={{background: openUserID ? "transparent" : ""}}*/ >
                        <AccordionSummary  >
                            {(expand && userIndex == i) || (name == "" || numberID == "") ?
                                // <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
                                <Grid container>
                                    <Grid xs={9} item>
                                        <Grid container>
                                            {(expand || (submitedFirstName === '')) && <>
                                                <Grid item> <AccountCircleIcon style={{ marginRight: "10px", color: '#007DFE' }} /> </Grid>
                                                <Grid item style={{ position: 'relative', top: '3px' }}>  
                                                    {(containerError && !expand) ? <Typography className='inputSecondarySectionLabelsError' children={"Isi Data Penumpang"} /> : 
                                                    <Typography 
                                                        children={passenger === "adult" ? `Dewasa ${i + 1}` : passenger === "child" ? `Anak ${(i + 1) - adultPassengers}` : `Bayi ${(i + 1) - (adultPassengers+childPassengers)}`}
                                                        className={containerError ? "inputSecondarySectionLabelsError" : "inputSecondarySectionLabels"} /> }
                                                </Grid>
                                                <Grid item>    <span style={{ color: "red", fontSize: "bold" }} >*</span> </Grid>
                                            </>
                                            }
                                            {(!expand && submitedFirstName !== '') && <>
                                                <Grid item>
                                                    <Grid container>
                                                        <Typography style={{ fontWeight: "600", fontSize: "12px", color: "#1A1A1A" }} children={submitedLastName ? submitedFirstName + ' ' + submitedLastName : submitedFirstName} />
                                                    </Grid>
                                                    {(documentType !== "") && <Grid container>
                                                        <Typography children={currentPaxDoc == "NATIONAL_ID" ? 'KTP: ' + numberID : currentPaxDoc == "PASSPORT" ? 'Passport No.: ' + submitedPassportNumber : ""} style={{ fontSize: "12px", color: "#666", marginTop: "5px" }} />
                                                    </Grid>}
                                                </Grid>
                                            </>
                                            }
                                        </Grid>
                                    </Grid>
                                    {(expand && userIndex == i) ?
                                        <Grid item style={{ left: '22%', position: 'relative' }}> <KeyboardArrowUpRoundedIcon style={{ fontSize: "16px" }} /> </Grid>
                                        :
                                        <Grid item style={{ left: '5%', position: 'relative' }}>
                                            <Grid container >
                                                <Grid item style={{ position: 'relative', top: '3px', marginRight: '3px' }}>  <EditIcon style={{ color: '#007DFE', fontSize: '15px' }} /> </Grid>
                                                <Grid item >  <Typography children={(submitedFirstName !== '') ? "Ubah" : "Isi Data"} style={{ fontSize: "14px", color: '#007DFE' }} /> </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                :
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <div >
                                        <Typography children={pFirstName} style={{ fontWeight: "bold", fontSize: "12px", color: "#1A1A1A", textTransform: "capitalize" }} />
                                        <Typography children={`${passengerID} - ${numberID}`} style={{ fontSize: "12px", color: "#666" }} />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }} >
                                        <EditRoundedIcon style={{ fontSize: "16px", marginRight: "2px" }} />
                                        <Typography children={"Ubah"} style={{ fontWeight: "bold", fontSize: "14px", color: "#1A1A1A" }} />
                                    </div>
                                </div>
                            }
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingTop: "0" }}>

                            <Divider />
                            <div>
                                <FormControl variant="filled" fullWidth error={error && salutation == ""}>
                                    <InputLabel id={`salutationLabel-${i}`} sx={{ margin: "12px 0" }}>Sapaan</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId={`salutationLabel-${i}`}
                                        style={{backgroundColor: '#F7F6F6'}}
                                        id={`salutationvalue-${i}`}
                                        value={salutation}
                                        onFocus={()=>setTitleFocus(true)}
                                        onBlur={()=>setTitleFocus(false)}
                                        label="Sapaan"
                                        disableUnderline={((error && salutation == "") || titleFocus) ? false : true}
                                        IconComponent={()=><KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />}
                                        error={error && salutation == ""}
                                        sx={{ fontWeight: 'bold', margin: "12px 0" }}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 25 && /^[a-zA-Z ]*$/.test(e.target.value))
                                                setSalutation(e.target.value)
                                        }}
                                    >
                                        <MenuItem value={'MR'}>Tuan</MenuItem>
                                        <MenuItem value={'MRS'}>Nyonya</MenuItem>
                                        <MenuItem value={'MISS'}>Nona</MenuItem>
                                    </Select>
                                    <FormHelperText style={{ right: '15px', bottom: '10px', position: 'relative' }}>{(salutation === "" && error) ? "Sapaan tidak boleh kosong" : ""}</FormHelperText>
                                </FormControl>
                                <TextField id={`pfirstName-value-${i}`} fullWidth autoComplete={"off"}
                                    label="Nama Depan & Tengah (jika ada)"
                                    value={pFirstName}
                                    onFocus={()=>setFirstNameFocus(true)}
                                    onBlur={()=>setFirstNameFocus(false)}
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: ((error && pFirstName.length < 2) || firstNameFocus) ? false : true,
                                      }}
                                    error={error && pFirstName.length < 2}
                                    inputProps={{ style: { fontWeight: 'bold', backgroundColor: '#F7F6F6' } }}
                                    sx={{ marginTop: '1%' }}
                                    helperText={(pFirstName.length < 2 && error) ? "Nama depan tidak boleh kosong" : ""}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 20 && /^[a-zA-Z ]*$/.test(e.target.value))
                                            setFirstName(e.target.value)
                                    }
                                    }
                                    FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                                />
                                <Typography children={"tanpa gelar dan tanda baca"} style={{ fontSize: "11px", color: "#979797", marginLeft: '5px' }} />

                                <div style={{ right: '10px', position: 'relative' }}>
                                    <Grid container>
                                        <Grid item> <Checkbox checked={noLastName} onClick={(e) => {
                                            setNoLastName(e.target.checked)
                                        }} /> </Grid>
                                        <Grid item>  <Typography children={"Tidak punya nama belakang"} style={{ fontSize: "12px", marginTop: '12px' }} /> </Grid>
                                    </Grid>
                                </div>

                                {!noLastName && <> <TextField id={`lastName-value-${i}`} fullWidth autoComplete={"off"}
                                    label="Nama Belakang"
                                    value={lastName}
                                    variant="filled"
                                    error={error && lastName.length < 2}
                                    InputProps={{
                                        disableUnderline: ((lastName.length < 2 && error) || lastNameFocus) ? false : true,
                                      }}
                                    onFocus={()=>setLastNameFocus(true)}
                                    onBlur={()=>setLastNameFocus(false)}
                                    inputProps={{ style: { fontWeight: 'bold', backgroundColor: '#F7F6F6' } }}
                                    sx={{ marginTop: '2%' }}
                                    helperText={(lastName.length < 2 && error) ? "Nama belakang tidak boleh kosong" : ""}
                                    onChange={(e) => {
                                        if(passenger === "infant" && e.target.value.length <=10 && /^[a-zA-Z ]*$/.test(e.target.value)) {
                                            setLastName(e.target.value)
                                        } else if (passenger !== "infant" && e.target.value.length <= 20 && /^[a-zA-Z ]*$/.test(e.target.value)) {
                                            setLastName(e.target.value)
                                        }
                                            
                                    }
                                    }
                                    FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                                />
                                    <Typography children={"tanpa gelar dan tanda baca"} style={{ fontSize: "11px", color: "#979797", marginLeft: '5px' }} />
                                </>
                                }
                                <div style={{ marginTop: '16px' }}>
                                    <div style={{ display: "flex", alignItems: "center", flex: "1" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={indonesian}>
                                            <MobileDatePicker InputProps = {{ 
                                                disableUnderline: true,
                                                // disableUnderline: (((error && (dateOfBirth === "" || dateOfBirth === null)) || (passengerAgeError ? true : false))) ? false : true, 
                                                style: ((error && (dateOfBirth === "" || dateOfBirth === null)) || (passengerAgeError)) ? { fontWeight: "bold", height: '56px', borderRadius: "4px 0px 0px 0px", backgroundColor: '#F7F6F6', borderBottom: "2px solid", borderColor: "#d32f2f" } : { height: '56px', borderRadius: "4px 0px 0px 0px", backgroundColor: '#F7F6F6', fontWeight: "bold" }, 
                                                    }} 
                                                disableFuture 
                                                DialogProps = {{ className: classes.selectDepartureDate }}
                                                // cancelText = {""}
                                                // open={isDobOpen}
                                                // onAccept={setDobOpen(false)}}
                                                value={dateOfBirth}
                                                label="Tanggal Lahir"
                                                onChange={(date) => {
                                                        date ? setDateofBirth(date) : ''
                                                        checkAgeValidation(date);
                                                    }
                                                }
                                                inputFormat="dd-MM-yyyy"
                                                maxDate={new Date()} 
                                                renderInput = {(params) => {
                                                    return (
                                                        <div style={{
                                                            display: 'flex',
                                                            width: '100%'
                                                        }}>
                                                            <TextField fullWidth variant="filled"
                                                                onClick={params.inputProps.onClick}
                                                                // onFocus={()=>{setDobFocus(true);}}
                                                                // onBlur={()=>{setDobFocus(false);}}
                                                                className={(error && (dateOfBirth === "" || dateOfBirth === null)) || (passengerAgeError) ? classes.departureDateTextFieldError : classes.departureDateTextField} {...params}
                                                                error={(error && (dateOfBirth === "" || dateOfBirth === null)) || (passengerAgeError) ? true : false}
                                                                helperText={error && (dateOfBirth === "" || dateOfBirth === null) ? "Tanggal lahir tidak boleh kosong" : (passengerAgeError) ? passengerAgeError : ""}
                                                                FormHelperTextProps={{style:{margin:"5px 0px 0px", color:"#c21616"}}}
                                                            />
                                                            <InsertInvitationOutlinedIcon onClick={params.inputProps.onClick}
                                                                    style={(error && (dateOfBirth === "" || dateOfBirth === null)) || (passengerAgeError) ? {height: "54px", paddingRight: '15.5px', color: "#ADA6AE", borderRadius: "0px 4px 0px 0px", backgroundColor: '#F7F6F6', borderBottom: "2px solid", borderColor: "#d32f2f"} : {height: "56px", paddingRight: '15.5px', color: "#ADA6AE", borderRadius: "0px 4px 0px 0px", backgroundColor: '#F7F6F6'}}/>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <FormControl variant="filled" fullWidth error={error && citizenship === ""}>
                                    <InputLabel id={`citizenshipLabel-${i}`} sx={{ marginTop: "4%" }}>Kewarganegaraan</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId={`citizenshipLabel-${i}`}
                                        id={`citizenshipValue-${i}`}
                                        value={citizenship}
                                        onFocus={()=>setCitizenshipFocus(true)}
                                        onBlur={()=>setCitizenshipFocus(false)}
                                        disableUnderline={((error && citizenship === "") || citizenshipFocus) ? false : true}
                                        IconComponent={()=><KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />}
                                        style={{backgroundColor: '#F7F6F6'}}
                                        error={error && citizenship === ""}
                                        label="Kewarganegaraan"
                                        sx={{ fontWeight: 'bold', marginTop: "4%" }}
                                        onChange={onChangeNationality}
                                    >
                                        {countryData.map((item, i) => {
                                            return (
                                                <MenuItem key={i} value={item.code}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText style={{ right: '15px', position: 'relative' }}>{(citizenship === "" && error) ? "Kewarganegaraan tidak boleh kosong" : ""}</FormHelperText>
                                </FormControl>
                            </div>

                            {passengerDocumentType === "NATIONAL_ID" && <div style={{ marginTop: "16px" }}>
                                <TextField id={`nomorID-value-${i}`} fullWidth className={classes.telInput} autoComplete={"off"}
                                    InputProps={{ disableUnderline: ((error && (
                                        (passengerNumberID === "" || passengerNumberID .length< 16)
                                    )) || nomorIdFocus) ? false : true,
                                    style: { borderRadius: "4px", backgroundColor: '#F7F6F6', fontWeight: "bold"} }}
                                    value={passengerNumberID}
                                    type="text"
                                    variant="filled"
                                    label="Nomor ID"
                                    onFocus={()=>setNomorIdFocus(true)}
                                    onBlur={()=>setNomorIdFocus(false)}
                                    onChange={(e) => {
                                        if(e.target.value.length <= 16){setPassengerNumberID(e.target.value);}
                                    }}
                                    error={
                                        error && (
                                            (passengerNumberID === "" || passengerNumberID .length< 16)
                                        )
                                    }
                                    FormHelperTextProps={{ style: { margin: "0", color: "#c21616" } }}
                                    helperText={
                                        error && ((passengerNumberID === "") ? "Nomor ID tidak boleh kosong." : passengerNumberID.length < 16 ? "Nomor Identitas harus 16 digit" : "")
                                    }
                                />
                            </div>}

                            {passengerDocumentType === "PASSPORT" && <div style={{ marginTop: "16px" }}>
                                <TextField id={`passport-no-${i}`} fullWidth className={classes.telInput} autoComplete={"off"}
                                    InputProps={{ disableUnderline: ((error && (
                                        (passportNo === "" || passportNo.length < 7)
                                    )) || passportNumFocus) ? false : true, style: { borderRadius: "4px", backgroundColor: '#F7F6F6', fontWeight: "bold" } }}
                                    value={passportNo}
                                    type="text"
                                    variant="filled"
                                    label="Nomor Paspor"
                                    onFocus={()=>setPassportNumFocus(true)}
                                    onBlur={()=>setPassportNumFocus(false)}
                                    onChange={(e) => {
                                        if(e.target.value.length <= 10){setPassportNo(e.target.value);}
                                    }}
                                    error={
                                        error && (
                                            (passportNo === "" || passportNo.length < 7)
                                        )
                                    }
                                    FormHelperTextProps={{ style: { margin: "0", color: "#c21616" } }}
                                    helperText={
                                        error && ((passportNo === "") ? "Nomor Paspor tidak boleh kosong." : passportNo.length < 7 ? "Nomor passport harus 7 sampai 10 digit." : "")
                                    }
                                />
                            </div>}
                            {passengerDocumentType === "PASSPORT" && <div>
                                <FormControl variant="filled" fullWidth error={error && passportIssuingCountry === ""}>
                                    <InputLabel id={`passportIssuingCountryLabel-${i}`} sx={{ marginTop: "4%" }}>Negara Penerbit</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId={`passportIssuingCountryLabel-${i}`}
                                        id={`passportIssuingCountry-${i}`}
                                        value={passportIssuingCountry}
                                        disableUnderline={((error && passportIssuingCountry === "") || passportIssueFocus) ? false : true}
                                        IconComponent={()=><KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />}
                                        style={{backgroundColor: '#F7F6F6'}}
                                        onFocus={()=>setPassportIssueFocus(true)}
                                        onBlur={()=>setPassportIssueFocus(false)}
                                        error={error && passportIssuingCountry === ""}
                                        label="Negara Penerbit"
                                        sx={{ fontWeight: 'bold', marginTop: "4%" }}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 25 && /^[a-zA-Z ]*$/.test(e.target.value))
                                                setPassportIssuingCountry(e.target.value)
                                        }}
                                    >
                                        {countryData.map((item, i) => {
                                            return (
                                                <MenuItem key={i} value={item.code}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText style={{ right: '15px', position: 'relative' }}>{(passportIssuingCountry === "" && error) ? "Negara Penerbit boleh kosong" : ""}</FormHelperText>
                                </FormControl>
                            </div>
                            }
                            {passengerDocumentType === "PASSPORT" && <>
                            <div style={{ marginTop: '16px' }}>
                                <div style={{ display: "flex", alignItems: "center", flex: "1" }}>

                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={indonesian}>
                                    <MobileDatePicker InputProps = {{ disableUnderline: true, 
                                    style: getPassportDateError() ? { fontWeight: "bold", height: '56px', borderRadius: "4px 0px 0px 0px", backgroundColor: '#F7F6F6', borderBottom: "2px solid", borderColor: "#d32f2f" } : { height: '56px', borderRadius: "4px 0px 0px 0px", backgroundColor: '#F7F6F6', fontWeight: "bold" }, 
                                            }}
                                        DialogProps = {{ className: classes.selectDepartureDate }}
                                        // cancelText = {""}
                                        // open={isPassportExpOpen}
                                        // onAccept={()=>setPassportExpOpen(false)}
                                        value={passportExpirationDate}
                                        label="Tanggal Habis Berlaku"
                                        onChange={(date) => {
                                            if(date) {
                                                setPassportExpirationDate(date);
                                                }
                                            }}
                                        inputFormat="dd-MM-yyyy"
                                        minDate={new Date()} 
                                        renderInput = {(params) => {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    width: '100%'
                                                }}>
                                                    <TextField variant="filled"
                                                        onClick={params.inputProps.onClick}
                                                        // onFocus={()=>{setPassportExpirationFocus(true)}}
                                                        // onBlur={()=>{setPassportExpirationFocus(false)}}
                                                        fullWidth className={classes.departureDateTextField} {...params}
                                                        error={getPassportDateError() ? true : false}
                                                        helperText={getPassportDateError()}
                                                        FormHelperTextProps={{style:{margin:"5px 0px 0px", color:"#c21616"}}}
                                                    />
                                                    <InsertInvitationOutlinedIcon onClick={params.inputProps.onClick}
                                                                    style={getPassportDateError() ? {height: "54px", paddingRight: '15.5px', color: "#ADA6AE", borderRadius: "0px 4px 0px 0px", backgroundColor: '#F7F6F6', borderBottom: "2px solid", borderColor: "#d32f2f"} : {height: "56px", paddingRight: '15.5px', color: "#ADA6AE", borderRadius: "0px 4px 0px 0px", backgroundColor: '#F7F6F6'}}/>
                                                </div>
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div></>

                            }

                            <Button id={`simpanPassenger-${i}`} fullWidth children={"Simpan"} className={"submitButton"}
                                onClick={() => handleSubmitPassengerInfo()}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>)}
            )
            }
        </div >
    )
}

export default PassengerInfo;

const useStyle = makeStyles({
    telInput: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    selectDepartureDate: {
        '& .MuiDialogActions-root>:first-of-type': {
            display: 'none'
          },
        // giv a line after the date displayed at top in Date Picker
        '& .PrivatePickersToolbar-root': {
            border: "1px solid #DDD !important"
        },
        // removed the edit button which changed the view of the date picker
        '& .MuiPickersToolbar-penIconButton': {
            display: "none",
        },
        // change color and background of the CANCEL and OK button
        '& .MuiButton-root': {
            background: "#007DFE",
            color: "#FFF",
            '&:hover': {
                background: "#007DFE",
            }
        },
        // background color applied to date when selected and also when seledted and hover on it
        '& .Mui-selected': {
            backgroundColor: "#007DFE !important",
            '&:hover': {
                backgroundColor: "#007DFE !important"
            }
        }
    },
    departureDateTextField: {
        // style APPLIED to TEXTFIELD 
        '& .MuiOutlinedInput-input': {
            padding: "10px 14px !important",
            fontSize: "14px !important",
        }
    }
})