/* eslint-disable */
import React, { useState } from "react";
import BaggageRadio from "./BaggageRadio";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { JOURNEY_TYPE } from "../StationSelection/StationSelection";
import { isEmptyObj } from "../../common/Utils";
import {
  getBaggageDetailsFromBaggageSelectionLocalData,
  getPassengerNameFromObject,
} from "./BaggageService";

const Baggage = ({
  baggageDataObject,
  passengerDetails,
  onChange,
  baggageSelectionLocalData,
}) => {
  // console.log({
  //   baggageDataObject,
  //   passengerDetails,
  //   baggageSelectionLocalData,
  //   onChange,
  // });

  // console.log({ baggageSelectionLocalData });

  const setDefaultBaggageOption = (segment, passenger) => {
    for (const option of segment.baggageOptions) {
      if (parseInt(option.priceWithCurrency.amount) === 0) {
        //default option
        onChange(segment, passenger, option);
      }
    }
  };

  const getSegmentEleBasedOnPassengers = (segment, passengers) => {
    return (
      <>
        <div className="bag-airports-box">
          <img src={segment.airline.logoUrl} style={{}} />
          <div className="bag-airports">
            <span>{segment.depAirport}</span>
            <ArrowForwardIcon
              className="bag-arrow"
              style={{
                width: 16,
                height: 16,
              }}
            />
            <span>{segment.arrAirport}</span>
          </div>
        </div>
        <div className="bag-pass-container">
          {/* multiple passengers */}
          {passengers.map((passenger, index) => {
            if (passenger.passenger === "infant") {
              //we don't want baggage options for infants
              return null;
            }
            const { baggageWeight, baggageType } =
              getBaggageDetailsFromBaggageSelectionLocalData(
                baggageSelectionLocalData,
                segment,
                passenger
              );

            if (!baggageWeight) {
              //no baggage option selected
              setDefaultBaggageOption(segment, passenger);
            }

            return (
              <div key={index} className="bag-pass-box">
                <div className="bag-passenger">
                  <span>{getPassengerNameFromObject(passenger)}</span>
                  {baggageWeight && (
                    <span>{`Total ${baggageWeight}${baggageType}`}</span>
                  )}
                </div>
                <BaggageRadio
                  segment={segment}
                  passenger={passenger}
                  selected={baggageWeight}
                  baggageSelectionLocalData={baggageSelectionLocalData}
                  onChange={onChange}
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const getBaggageEleForFlight = (title, baggageData) => {
    return (
      <>
        <div className="bag-comp">
          <div className="bag-flight-type">
            <span>{title}</span>
          </div>

          <div className="bag-segment-container">
            {baggageData.segments.map((segment, i) => (
              <div key={i} className="bag-segment-box">
                {getSegmentEleBasedOnPassengers(segment, passengerDetails)}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const getBaggageEle = () => {
    let eleOneWay = null;
    let eleRound = null;

    if (!isEmptyObj(baggageDataObject[JOURNEY_TYPE.OneWay])) {
      eleOneWay = getBaggageEleForFlight(
        "Penerbangan Pergi",
        baggageDataObject[JOURNEY_TYPE.OneWay]
      );
    }

    if (!isEmptyObj(baggageDataObject[JOURNEY_TYPE.RoundTrip])) {
      eleRound = getBaggageEleForFlight(
        "Penerbangan Pulang",
        baggageDataObject[JOURNEY_TYPE.RoundTrip]
      );
    }
    return (
      <>
        {eleOneWay}
        {eleRound}
      </>
    );
  };

  return <>{getBaggageEle()}</>;
};

export default Baggage;
