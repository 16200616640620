import {
  getValueStringOrEmptyString,
  isEmptyObj,
  parseFloatToDecimals,
  priceFormatter,
  salutationLocalizeMap,
} from "../../common/Utils";
import { JOURNEY_TYPE } from "../StationSelection/StationSelection";

export const KEY_SEPARATOR = ":";

export const isEmptyPassengerList = (passengerList) =>
  passengerList.some((passenger) => passenger.pFirstName === "");

export const getPassengerNameFromObject = (passenger) =>
  `${salutationLocalizeMap[passenger?.salutation] ?? ""} ${
    passenger.pFirstName ?? ""
  } ${passenger.lastName ?? ""}`;

const getBaggageDataObjectForFlight = (
  baggageOption,
  flightData,
  initialFormData,
  airlines
) => {
  let baggageData = {};
  baggageData["depAirport"] = flightData.departureAirport;
  baggageData["arrAirport"] = flightData.arrivalAirport;
  baggageData["segments"] = [];

  const isTransit = parseInt(flightData?.numOfTransits ?? 0) > 0;

  if (!isTransit && !isEmptyObj(baggageOption?.availableAddOnsOptions)) {
    //no transits
    const addOnObj = {};
    addOnObj["depAirport"] = flightData.departureAirport;
    addOnObj["arrAirport"] = flightData.arrivalAirport;
    addOnObj["airline"] =
      airlines[flightData.journeys[0].segments[0].operatingAirline] || {};
    addOnObj["baggageOptions"] =
      baggageOption?.availableAddOnsOptions.baggageOptions;
    baggageData["segments"].push(addOnObj);
  } else if (
    isTransit &&
    baggageOption?.segmentsWithAvailableAddOns?.length > 0
  ) {
    //transits
    for (const segmentItem of baggageOption.segmentsWithAvailableAddOns) {
      const addOnObj = {};

      addOnObj["depAirport"] = segmentItem.segment.sourceAirport;
      addOnObj["arrAirport"] = segmentItem.segment.destinationAirport;
      addOnObj["airline"] =
        airlines[segmentItem.segment.operatingAirline] || {};
      addOnObj["baggageOptions"] =
        segmentItem.availableAddOnsOptions.baggageOptions;

      baggageData["segments"].push(addOnObj);
    }
  } else if (isTransit && !isEmptyObj(baggageOption?.availableAddOnsOptions)) {
    const addOnObj = {};
    addOnObj["depAirport"] = flightData.departureAirport;
    addOnObj["arrAirport"] = flightData.arrivalAirport;
    addOnObj["airline"] =
      airlines[flightData.journeys[0].segments[0].operatingAirline] || {};
    addOnObj["baggageOptions"] =
      baggageOption?.availableAddOnsOptions.baggageOptions;
    baggageData["segments"].push(addOnObj);
  } else {
    //("No Baggage data found for flight");
    return {};
  }
  return baggageData;
};

export const getBaggageDataObject = (
  baggageOptions,
  flightDataOneWay,
  flightDataRound,
  initialFormData,
  airlines
) => {
  if (!baggageOptions) {
    return null;
  }

  let baggageData = {
    [JOURNEY_TYPE.OneWay]: {},
    [JOURNEY_TYPE.RoundTrip]: {},
  };

  if (baggageOptions.length > 0 && !isEmptyObj(flightDataOneWay)) {
    //this means we have baggage options available for one way
    baggageData[JOURNEY_TYPE.OneWay] = getBaggageDataObjectForFlight(
      baggageOptions[0],
      flightDataOneWay,
      initialFormData,
      airlines
    );
  }

  if (baggageOptions.length > 1 && !isEmptyObj(flightDataRound)) {
    //this means we have baggage options available for round way
    baggageData[JOURNEY_TYPE.RoundTrip] = getBaggageDataObjectForFlight(
      baggageOptions[1],
      flightDataRound,
      initialFormData,
      airlines
    );
  }

  return baggageData;
};

export const generateBaggageSelectionKey = (segment, passenger) => {
  return `${getValueStringOrEmptyString(
    segment.depAirport
  )}${KEY_SEPARATOR}${getValueStringOrEmptyString(
    segment.arrAirport
  )}${KEY_SEPARATOR}${getValueStringOrEmptyString(
    passenger.pFirstName
  )}${KEY_SEPARATOR}${getValueStringOrEmptyString(
    passenger.lastName
  )}${KEY_SEPARATOR}${passenger.id}`;
};

export const getBaggageDetailsFromBaggageSelectionLocalData = (
  baggageSelectionLocalData,
  segment,
  passenger
) => {
  const key = generateBaggageSelectionKey(segment, passenger);
  const baggage = baggageSelectionLocalData[key];
  // console.log({ baggage });
  return {
    baggageWeight: baggage?.baggageWeight ?? "",
    baggageType: baggage?.baggageType ?? "",
  };
};

export const calculateTotalBaggagePrice = (baggageSelectionLocalData) => {
  return Object.keys(baggageSelectionLocalData).reduce(
    (acc, key) =>
      acc +
      parseFloatToDecimals(
        baggageSelectionLocalData[key]?.priceWithCurrency?.amount || 0
      ),
    0
  );
};

const getAddonsDataForBaggage = (
  baggageOption,
  baggageSelectionData,
  flightData,
  passenger
) => {
  const tmpAddon = {
    availableAddOnsOptions: {},
    segmentsWithAvailableAddOns: [],
  };
  const flightDataJourney = flightData.journeys[0];
  const isTransit = parseInt(flightData?.numOfTransits ?? 0) > 0;

  if (!isTransit && !isEmptyObj(baggageOption?.availableAddOnsOptions)) {
    //no transits
    for (const [key, localBaggageOption] of Object.entries(
      baggageSelectionData
    )) {
      const [depAirport, arrAirport, firstName, lastName, id] =
        key.split(KEY_SEPARATOR);

      if (
        firstName !== passenger.pFirstName ||
        lastName !== passenger.lastName ||
        parseInt(id) !== parseInt(passenger.id)
      ) {
        continue;
      }

      if (
        flightDataJourney.departureDetail.airportCode !== depAirport ||
        flightDataJourney.arrivalDetail.airportCode !== arrAirport
      ) {
        continue;
      }
      tmpAddon.availableAddOnsOptions["baggageOptions"] = [localBaggageOption];
      tmpAddon.availableAddOnsOptions["mealOptions"] = [];
    }
  } else if (
    isTransit &&
    baggageOption?.segmentsWithAvailableAddOns?.length > 0
  ) {
    // transits
    for (const segmentItem of baggageOption.segmentsWithAvailableAddOns) {
      for (const [key, localBaggageOption] of Object.entries(
        baggageSelectionData
      )) {
        const [depAirport, arrAirport, firstName, lastName, id] =
          key.split(KEY_SEPARATOR);

        if (
          firstName !== passenger.pFirstName ||
          lastName !== passenger.lastName ||
          parseInt(id) !== parseInt(passenger.id)
        ) {
          continue;
        }

        if (
          segmentItem.segment.sourceAirport !== depAirport ||
          segmentItem.segment.destinationAirport !== arrAirport
        ) {
          continue;
        }

        tmpAddon.segmentsWithAvailableAddOns.push({
          segment: segmentItem.segment,
          availableAddOnsOptions: {
            baggageOptions: [localBaggageOption],
            mealOptions: [],
          },
        });
      }
    }
  }
  else if(isTransit && !isEmptyObj(baggageOption?.availableAddOnsOptions)){
    
    for (const [key, localBaggageOption] of Object.entries(
      baggageSelectionData
    )) {
      const [depAirport, arrAirport, firstName, lastName, id] =
        key.split(KEY_SEPARATOR);

      if (
        firstName !== passenger.pFirstName ||
        lastName !== passenger.lastName ||
        parseInt(id) !== parseInt(passenger.id)
      ) {
        continue;
      }

      if (
        flightDataJourney.departureDetail.airportCode !== depAirport ||
        flightDataJourney.arrivalDetail.airportCode !== arrAirport
      ) {
        continue;
      }
      tmpAddon.availableAddOnsOptions["baggageOptions"] = [localBaggageOption];
      tmpAddon.availableAddOnsOptions["mealOptions"] = [];
    }

  }
  return tmpAddon;
};

export const getPassengerAddonsDetails = (
  passenger,
  baggageSelectionData,
  baggageOptions,
  flightDataOneWay,
  flightDataRound
) => {
  if (isEmptyObj(baggageSelectionData)) {
    return [];
  }
  const isReturnJourneyAlso = !isEmptyObj(flightDataRound);

  const addOns = [];

  addOns[0] = getAddonsDataForBaggage(
    baggageOptions[0],
    baggageSelectionData,
    flightDataOneWay,
    passenger
  );
  if (isReturnJourneyAlso) {
    addOns[1] = getAddonsDataForBaggage(
      baggageOptions[1],
      baggageSelectionData,
      flightDataRound,
      passenger
    );
  }

  // console.log({
  //   passenger,
  //   baggageSelectionData,
  //   baggageOptions,
  //   flightDataOneWay,
  //   flightDataRound,
  // });
  return addOns;
};

export const getBaggageSummary = (
  baggageDataObject,
  baggageSelectionLocalData
) => {
  return (baggageDataObject?.segments ?? []).map((segment) => {
    let summaryTotal = 0;
    for (const [k, v] of Object.entries(baggageSelectionLocalData)) {
      const [tmpDep, tmpArr] = k.split(":");

      if (tmpDep === segment.depAirport && tmpArr === segment.arrAirport) {
        summaryTotal += parseFloatToDecimals(v?.priceWithCurrency?.amount || 0);
      }
    }

    return {
      depAirport: segment.depAirport,
      arrAirport: segment.arrAirport,
      price: priceFormatter(summaryTotal),
    };
  });
};
