/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Header } from "../index";

import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import './../../Styles/TicketDetails.css';
import { FLIGHT_TYPES, getDateInFormat, getDurationLabel, getTotalFare, getWagonClass, parseFloatToDecimals, passengerClassMap, priceFormatter } from '../../common/Utils';

import { isEmptyObj } from '../../common/Utils';
import useLocalstorage, { APP_LOCAL_STORAGE_KEYS } from '../../hooks/useLocalstorage';
import { JOURNEY_TYPE } from '../StationSelection/StationSelection';
import moment from 'moment';

const TicketDetails = () => {
    const navigate = useNavigate();
    const [ticketDetails, setTicketDetails] = useState({
        segments:[],
        isRoundTrip:false,
        depCity:"",
        depAirportCode:"",
        arrCity:"",
        arrAirportCode:"",
        note:"",
        totalFare:"",
    });
    
    const {set:setLocalStorageValue, get: getLocalStorageValue} = useLocalstorage();
    const flightType = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing);
    
    useEffect(() => {
        
        const selectedFlightDetails = flightType === FLIGHT_TYPES.roundTrip ? getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsRound) : getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsOneWay)

        const initialFormData = getLocalStorageValue("INITIAL_FORM_DATA");

        const airlines = getLocalStorageValue("AIRLINES");

        const airportsByCode = getLocalStorageValue("AIRPORTS_BY_CODE");

        if(isEmptyObj(selectedFlightDetails)){
            return;
        }

        
        const isRoundTrip = initialFormData.journeyType === JOURNEY_TYPE.RoundTrip;

        
        const adultCount = initialFormData.passengers.adult;
        const childCount = initialFormData.passengers.child;
        const infantCount = initialFormData.passengers.infant;

        const totalFare = priceFormatter(parseFloatToDecimals(getTotalFare({
            adultFare: selectedFlightDetails.journeys[0].fareInfo.partnerFare.adultFare.totalFareWithCurrency.amount.split(".")[0],
            childFare: selectedFlightDetails.journeys[0].fareInfo.partnerFare.childFare.totalFareWithCurrency.amount.split(".")[0],
            infantFare: selectedFlightDetails.journeys[0].fareInfo.partnerFare.infantFare.totalFareWithCurrency.amount.split(".")[0],
            adultCount,
            childCount,
            infantCount
        })));
        
        const note = {
            title: "Ketentuan Refund",
            content: "Refund bisa dilakukan di platform Traveloka jika email pemesan telah terhubung dengan akun Traveloka atau bisa menghubungi Customer Care di 0214-0634-900 atau +622129103300"
        }
        const baggageOptions = selectedFlightDetails.journeys[0].segments[0].addOns.baggageOptions;

        const overallDepCity = airportsByCode[selectedFlightDetails.journeys[0].departureDetail.airportCode].city;

        const overallArrCity = airportsByCode[selectedFlightDetails.journeys[0].arrivalDetail.airportCode].city;

        const overAllDepAirportCode = selectedFlightDetails.departureAirport;
        const overAllArrAirportCode = selectedFlightDetails.arrivalAirport;
        
        let allSegments = [];
        for (const transitSegment of selectedFlightDetails.journeys[0].segments) {
            const depDay = getDateInFormat(transitSegment.departureDetail.departureDate, "ddd");
            const depDate = transitSegment.departureDetail.departureDate;
            const depTime = transitSegment.departureDetail.departureTime;
            const depAirportCode = transitSegment.departureDetail.airportCode;
            const depTerminal = transitSegment.departureDetail.departureTerminal;
            const depCity = airportsByCode[transitSegment.departureDetail.airportCode].city;
    
            const arrDay = getDateInFormat(transitSegment.arrivalDetail.arrivalDate, "ddd");
            const arrDate = transitSegment.arrivalDetail.arrivalDate;
            const arrTime = transitSegment.arrivalDetail.arrivalTime;
            const arrAirportCode = transitSegment.arrivalDetail.airportCode;
            const arrTerminal = transitSegment.arrivalDetail.arrivalTerminal;
            const arrCity = airportsByCode[transitSegment.arrivalDetail.airportCode].city;
            const flightCode = transitSegment.flightCode;
            const {logoUrl:airlineLogo, airlineName} = airlines[transitSegment.operatingAirline];
            const journeyDuration = getDurationLabel(transitSegment.flightDurationInMinutes);

            const tmpSegment = {
                depDay,
                depDate,
                depTime,
                depCity,
                depTerminal,
                depAirportCode,
                arrDate,
                arrDay,
                arrTime,
                arrCity,
                arrTerminal,
                arrAirportCode,
                airlineName,
                airlineLogo,
                flightCode,
                journeyDuration,
                baggageOptions,
            };
            allSegments = [...allSegments, tmpSegment];
        }
        setTicketDetails({
            segments: allSegments,
            isRoundTrip,
            depCity:overallDepCity,
            depAirportCode:overAllDepAirportCode,
            arrCity:overallArrCity,
            arrAirportCode:overAllArrAirportCode,
            note,
            totalFare    
        });
    },[]);

    const handleSubmit = () => {
        if(flightType === FLIGHT_TYPES.oneWay && ticketDetails.isRoundTrip){
            setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.flightTypeForListing, FLIGHT_TYPES.roundTrip);
            navigate(`/stationpricecard`);
        }else{
            navigate(`/inputdata`);
        }
    };
    const initialFormData = getLocalStorageValue("INITIAL_FORM_DATA");
    const header =  `${ticketDetails.depCity} (${ticketDetails.depAirportCode}) - ${ticketDetails.arrCity} (${ticketDetails.arrAirportCode})`;
    return (
        <div style={{ height: "100vh" }}>
            <Header header={header} back={"/stationpricecard"} />
            <div style={{padding:"16px 0", height: `calc(100vh - 180px)`, overflowY:"auto"}}>

            {
                ticketDetails.segments.map((segment, index) => {
                    return (
                        <>
                        <Grid container style={{flexWrap:"inherit", paddingRight: 16}}>
                        <Grid item>
                            <div className='vl-1'>
                                <div className="icon">
                                    <CircleOutlinedIcon className='circleIcon'></CircleOutlinedIcon>
                                </div>
                                <div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
                                <div className="icon">
                                    <CircleIcon className='circleIcon iconBlue'></CircleIcon>
                                </div>
                            </div>
                        </Grid>
                        <Grid item className='tMargin1'>
                            <Typography id="headerText" className='headerText'>
                            {`${moment(segment.depDate, 'MM-DD-YYYY').locale('id').format("ddd, DD MMM YYYY")} - ${segment.depTime}`}
                            </Typography>
                            <Typography className='tSecSize'>
                                {`${segment.depCity} (${segment.depAirportCode})`} {segment.depTerminal && `- Terminal `} {segment.depTerminal}
                                </Typography>
                            <Paper style={{marginBottom: "20px"}} className='paperCards tMargin2 paperW'>
                                <Grid container>
                                    <Grid item>
                                        <img src={segment.airlineLogo} style={{ width: '10px', height: '10px', marginRight: '4px', padding: '3px', borderRadius: '50%', border: '1px solid lightgrey', objectFit: "contain" }} />
                                    </Grid>
                                    <Grid item style={{marginLeft:'7px'}}>
                                        <Typography className='tFlight' children={segment.airlineName} />
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginLeft:'28px'}}>
                                    <Grid item>
                                        <Typography sx={{ fontWeight: '400', fontSize: '12px', marginRight: '2px', color: '#615A5A' }} children={`${segment.flightCode} `} />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ fontWeight: '400', fontSize: '12px', bottom: '4px', position: 'relative', marginRight: '2px', color: '#615A5A' }} children={' . '} />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ fontWeight: '400', fontSize: '12px', marginRight: '2px', color: '#615A5A' }} children={passengerClassMap[initialFormData.seatClass]} />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ fontWeight: '400', fontSize: '12px', bottom: '4px', position: 'relative', marginRight: '2px', color: '#615A5A' }} children={' . '} />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ fontWeight: '400', fontSize: '12px', color: '#615A5A' }} children={`${segment.journeyDuration}`} />
                                    </Grid>
                                </Grid>
                                <Divider className='tMargin2' />
                                <Grid container className="tMargin2">
                                    <Grid item>
                                        <BusinessCenterOutlinedIcon></BusinessCenterOutlinedIcon>
                                    </Grid>
                                    <Grid item style={{marginLeft:'7px'}}>
                                        {
                                            segment.baggageOptions.map((baggage, index) => {
                                                return (<Grid key={index} container>
                                                    <Typography sx={{ fontWeight: '400', fontSize: '14px', color: '#615A5A' }} 
                                                    children={<span>
                                                        Periksa peraturan maskapai untuk bagasi kabin <br />
                                                        {`Bagasi: ${baggage.baggageWeight} ${baggage.baggageType}`}
                                                    </span>} />
                                                </Grid>)
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                    
                            <div container className="tMargin3">
                                    <Typography id="headerText" className='headerText'>
                                        {`${moment(segment.arrDate, 'MM-DD-YYYY').locale('id').format("ddd, DD MMM YYYY")} - ${segment.arrTime}`}
                                        </Typography>
                                    <Typography className='tSecSize'>
                                        {`${segment.arrCity} (${segment.arrAirportCode})`} {segment.arrTerminal && `- Terminal `} {segment.arrTerminal}
                                    </Typography>
                                </div>
                        </Grid>
                    </Grid>
                        {(index === ticketDetails.segments.length -1) ? <div style={{
                            height: '8px',
                            marginTop: "20px",
                            background:"#F7F6F6"
                        }}></div> : 
                        <div style={{
                            height: '8px',
                            margin: "20px 0",
                            background:"#F7F6F6"
                        }}></div>}
                        </>
                );
                })
            }   
                <Paper className='paperCards'>
                    <Typography id="headerText" className='headerText tMargin1'>{ticketDetails.note.title}</Typography>
                    <Typography className='tSecSize tMargin1'>{ticketDetails.note.content}</Typography>
                </Paper>
            </div>
            <div id='footer' className="">
                <Paper className='paperCards' style={{paddingTop: "0px"}}>
                    <Grid container>
                        <Grid item xs={8} style={{ marginTop: '28px' }}>
                            <Typography id="GRATIS" children={"Total"} style={{ fontSize: "12px", color: "#615A5A" }} />
                            <Typography id="GRATIS" children={`Rp ${ticketDetails.totalFare}`} style={{ fontWeight: 'bold', fontSize: "18px", color: "#007DFE" }} />
                        </Grid>
                        <Grid item xs={4}>
                            <Button id={"Pesan"} fullWidth children={"Pilih Tiket"} variant={"contained"} className={"submitButton"}
                                onClick={()=> handleSubmit()}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </div>
    )
};

export default TicketDetails;

