import React from "react";
import { Button, Slide, Typography } from "@mui/material";
import { priceFormatter } from "../../common/Utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BaggageSummaryItem from "./BaggageSummaryItem";

function BaggageSummary({
  isSummaryExpanded,
  setIsSummaryExpanded,
  bagSummary,
  totalBaggagePrice,
  onSubmit,
}) {
  const getBaggageSummaryEle = () => {
    return (
      <div>
        {bagSummary.oneWaySummary.map((summary) => (
          <BaggageSummaryItem
            key={`${summary.depAirport} : ${summary.arrAirport}`}
            summary={summary}
          />
        ))}
        {bagSummary.roundWaySummary.map((summary) => (
          <BaggageSummaryItem
            key={`${summary.depAirport} : ${summary.arrAirport}`}
            summary={summary}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        background: "#80808078",
      }}
      className="baggage-container"
    >
      {/* <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          top: `${height}vh`,
          margin: "auto",
          maxWidth: "640px",
          borderRadius: "16px 16px 0 0",
          background: "#FFF",
        }}
      > */}
        <Slide
          direction={"up"}
          in={isSummaryExpanded}
          className={"bag-summary-slider"}
          id="slider-container"
        >
          <div>
            <div className="bag-submit-box ">
              <div>
                <div className="flex" onClick={() => setIsSummaryExpanded(false)}>
                  <Typography children={"Subtotal"} className="label-1" />
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    className="color-app-blue"
                  />
                </div>
                <Typography
                  children={`Rp ${priceFormatter(totalBaggagePrice)}`}
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                />
              </div>
              <Button
                id={"Simpan"}
                children={"Simpan"}
                variant={"contained"}
                className={"submitButton"}
                onClick={onSubmit}
              />
            </div>
            <div className="bag-summary-box border-t-1">
              {getBaggageSummaryEle()}
            </div>
          </div>
        </Slide>
      {/* </div> */}
    </div>
  );
}

export default BaggageSummary;
