/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, Paper, Slide, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { popularAirports } from '../../common/PopularAirports';

const SelectStationDropDown = ({ list, slideOpen, setOpenStationDropDown, setStationName, title }) => {

    const [name, setName] = useState("");
    const [stationArray, setStation] = useState([]);
    const [selectedStation, setSelectedStation] = useState();
    const [inputFocus, setFocus] = useState(false);

    useEffect(() => {
        if(name!="") {
            let sortedStationList = [];
            for(let i=0; i<list.length; i++) {
                let isStationName = list[i].city.toLowerCase().includes(name.toLocaleLowerCase());
                let isStationCode = list[i].airportCode.toLowerCase().includes(name.toLocaleLowerCase());
                let isAirportName = list[i].localAirportName.toLowerCase().includes(name.toLocaleLowerCase());
                if(isStationName || isStationCode || isAirportName) {
                    sortedStationList.push(list[i])
                }
            }
            setStation([...sortedStationList]);
            sortedStationList = [];
        }
        if(name=="") setStation([...popularAirports])
    }, [name]);

    // store all station names namesArray.
    
    useEffect(() => {
        if(selectedStation) {
            setStationName(selectedStation);
            setOpenStationDropDown(false)
        }
    }, [selectedStation]);
    

    return (
        <div style={{position: 'fixed', top:"0", bottom:"0", right:"0", left:"0", background:"rgba(0,0,0,0.5)", display: slideOpen ? "" : "none"}} >
            <div style={{position:"absolute", top:"25px", bottom:"0", right:"0", left:"0", margin: "auto", maxWidth:"640px", borderRadius:"16px 16px 0 0", background:"#FFF", display: slideOpen ? "" : "none"}}>
                <Slide direction={"up"} in={slideOpen} className={"sliderStationList"} id='slider-container' >
                    <Paper id="slider-paper" style={{height: slideOpen ? "100%" : "0%"}} >
                        <div style={{padding:"16px", paddingTop:"0", height:"100%"}} >

                            
                            <div className={"selectStationSliderHeader"}>
                                <Typography children={title} className='popUpHeaderText' id="headerText" />
                                <IconButton onClick={()=>{ setName(""); setOpenStationDropDown(false);}}> <CloseIcon style={{color:"#1A1A1A"}}/> </IconButton>
                            </div>
                            
                            <TextField fullWidth placeholder='Cari kota atau bandara'
                                onChange={(e)=>setName(e.target.value)} autoComplete={"off"}
                                value={name}
                                autoFocus
                                onFocus={()=>setFocus(true)}
                                InputProps={{
                                    className:inputFocus ? "searchAirportFieldOnFocus" : "searchAirportField",
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize='medium' style={{color:inputFocus ? "#007DFE":"#ADA6AE"}} />
                                            {/*
                                                inputFocus 
                                                && 
                                                <Typography children={"Cari"} style={{fontSize: '14px', fontWeight: 'bold', color: '#007DFE'}} id="headerText" />
                                            */}
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {name==="" && <div style={{marginTop: '15px'}}>
                                <Typography style={{fontSize: "14px", color: "#A2A2A2", fontWeight: "bold"}}>Bandara Populer</Typography>
                            </div>}

                            <div className={"stationlistContainer"}>
                            {
                                stationArray.map(({internationalAirportName,localAirportName, city, airportCode, areaCode}, i) => 
                                    <div key={i} className={"stationNameBlock"} onClick={()=> { setName(""); setSelectedStation(stationArray[i]) }} >
                                        <div>
                                            <Typography children={`${city}`} className={"stationName"} />
                                            <Typography children={localAirportName} style={{fontSize:"12px", color:"#1A1A1A"}} />
                                        </div>
                                        <Typography children={airportCode} className={"stationStatus"} />
                                    </div>
                                )
                            }
                            </div>

                        </div>
                    </Paper>
                </Slide>
            </div>
        </div>
    )
}

export default SelectStationDropDown;