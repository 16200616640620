/* eslint-disable */
import React, { useState } from "react";
import { Button, Paper, Slide, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Baggage from "./Baggage";
import "./Baggage.css";
import { JOURNEY_TYPE } from "../StationSelection/StationSelection";
import { isEmptyObj, priceFormatter } from "../../common/Utils";
import {
  calculateTotalBaggagePrice,
  generateBaggageSelectionKey,
  getBaggageSummary,
} from "./BaggageService";
import produce from "immer";
import BaggageSummary from "./BaggageSummary";

const BaggageContainer = ({
  openBagageSlider,
  toggleSlider,
  baggageDataObject,
  setBaggageSelectionData,
  passengerDetails,
  baggageSelectionData,
}) => {
  const [baggageSelectionLocalData, setBaggageSelectionLocalData] =
    useState(baggageSelectionData);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);

  const onSubmit = () => {
    setBaggageSelectionData({ ...baggageSelectionLocalData });
    toggleSlider();
  };

  const onBaggageChange = (segment, passenger, baggageOption) => {
    const key = generateBaggageSelectionKey(segment, passenger);
    setBaggageSelectionLocalData(
      produce(baggageSelectionLocalData, (draftBaggageSelectionLocalData) => {
        draftBaggageSelectionLocalData[key] = baggageOption;
      })
    );
  };

  const isNoBaggageOptions =
    isEmptyObj(baggageDataObject?.[JOURNEY_TYPE.OneWay]) &&
    isEmptyObj(baggageDataObject?.[JOURNEY_TYPE.RoundTrip]);

  const totalBaggagePrice = calculateTotalBaggagePrice(
    baggageSelectionLocalData
  );

  const baggageSummary = () => {
    let oneWaySummary = [];
    let roundWaySummary = [];

    oneWaySummary = getBaggageSummary(
      baggageDataObject[JOURNEY_TYPE.OneWay],
      baggageSelectionLocalData
    );

    if (baggageDataObject[JOURNEY_TYPE.RoundTrip]) {
      roundWaySummary = getBaggageSummary(
        baggageDataObject[JOURNEY_TYPE.RoundTrip],
        baggageSelectionLocalData
      );
    }

    return {
      oneWaySummary,
      roundWaySummary,
    };
  };
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        background: "#80808078",
        display: openBagageSlider ? "" : "none",
      }}
      className="baggage-container"
    >
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          top: 0,
          margin: "auto",
          maxWidth: "640px",
          background: "#FFF",
          display: openBagageSlider ? "" : "none",
        }}
      >
        <Slide
          direction={"left"}
          in={openBagageSlider}
          className={"bag-slider"}
          id="slider-container"
        >
          <Paper
            id="slider-paper"
            style={{ height: openBagageSlider ? "initial" : "0%" }}
          >
            <div
              style={{
                padding: "20px",
              }}
            >
              <Typography
                children={"Bagasi"}
                style={{
                  fontWeight: "700",
                  fontSize: "22px",
                }}
              />
            </div>
            {isNoBaggageOptions ? (
              <Typography
                children={"No baggage options available"}
                className=""
                style={{
                  color: "rgb(127, 127, 127)",
                  fontSize: 20,
                  marginTop: "2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "200px",
                }}
              />
            ) : (
              <>
                <div className="bag-paper-container ">
                  <div className="bag-body">
                    <Baggage
                      baggageDataObject={baggageDataObject}
                      passengerDetails={passengerDetails}
                      baggageSelectionLocalData={baggageSelectionLocalData}
                      onChange={onBaggageChange}
                    />
                  </div>
                </div>
                <div className="bag-submit-box position-absolute border-t-1">
                  <div>
                    <div>
                      <div
                        className="flex"
                        onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}
                      >
                        <Typography children={"Subtotal"} className="label-1" />
                        <KeyboardArrowUpIcon
                          fontSize="small"
                          className="color-app-blue"
                        />
                      </div>

                      <Typography
                        children={`Rp ${priceFormatter(totalBaggagePrice)}`}
                        style={{
                          fontWeight: "700",
                          fontSize: "18px",
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    id={"Simpan"}
                    children={"Simpan"}
                    variant={"contained"}
                    className={"submitButton"}
                    onClick={onSubmit}
                  />
                </div>
              </>
            )}
          </Paper>
        </Slide>
      </div>
      {isSummaryExpanded && (
        <div>
          <BaggageSummary
            {...{
              isSummaryExpanded,
              setIsSummaryExpanded,
              baggageDataObject,
              baggageSelectionLocalData,
              totalBaggagePrice,
              bagSummary: baggageSummary(),
              onSubmit,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BaggageContainer;