/* eslint-disable */
import React from 'react';

import { FormControlLabel, IconButton, Paper, Slide, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';


const SelectPassengerClass = ({ list, slideOpen, setOpenPassengerClass, setPassengerClass, value }) => {


    return (
        <div style={{position: 'fixed', top:"0", bottom:"0", right:"0", left:"0", background:"rgba(0,0,0,0.5)", display: slideOpen ? "" : "none"}} >
        <div style={{position:"absolute", bottom:"0", right:"0", left:"0", minHeight: "400px", margin: "auto", maxWidth:"640px", borderRadius:"16px 16px 0 0", background:"#FFF", display: slideOpen ? "" : "none"}}>
            <Slide direction={"up"} in={slideOpen} className={"sliderStationList"} id='slider-container' >
                <Paper id="slider-paper" style={{height: slideOpen ? "100%" : "0%"}} >
                    <div style={{padding:"16px", paddingTop:"0"}} >

                        <div className={"selectStationSliderHeader"}>
                            <Typography children={"Kelas Penerbangan"} className='popUpHeaderText' id="headerText" />
                            <IconButton onClick={()=>{ setOpenPassengerClass(false);}}> <CloseIcon style={{color:"#ADA6AE"}}/> </IconButton>
                        </div>

                        <div className={"stationlistContainer"}>
                            <FormControl style={{width: '100%'}}>
                                <RadioGroup value={value} onChange={(e) => {
                                    setPassengerClass(e.target.value);
                                    setOpenPassengerClass(false);
                                }}>
                                    <FormControlLabel className={"classOptions"} value="ECONOMY" control={<Radio />} label="Ekonomi" />
                                    <div className='passengerBlock'></div>
                                    <FormControlLabel className={"classOptions"} value="BUSINESS" control={<Radio />} label="Bisnis" />
                                    <div className='passengerBlock'></div>
                                    <FormControlLabel className={"classOptions"} value="PREMIUM_ECONOMY" control={<Radio />} label="Ekonomi Premium" />
                                    <div className='passengerBlock'></div>
                                    <FormControlLabel className={"classOptions"} value="FIRST" control={<Radio />} label="First Class" />
                                    <div className='passengerBlock'></div>
                                </RadioGroup>
                            </FormControl>
                        </div>

                    </div>
                </Paper>
            </Slide>
        </div>
        </div>
    )
}

export default SelectPassengerClass;