/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Accordion, InputLabel, AccordionDetails, AccordionSummary, Button, Divider, Grid, MenuItem, FormHelperText, Select, TextField, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { countryData } from '../../common/Country';

const ContactDetails = ({ submitError,setContactError }) => {
    const classes = useStyle();
    const [openContactDetails, setOpenContactDetails] = useState(false);
    const [salutation, setSalutation] = useState('')
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneCode, setPhoneCode] = useState("62");
    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");

    const [titleFocus, setTitleFocus] = useState(false)
    const [firstNameFocus, setFirstNameFocus] = useState(false)
    const [lastNameFocus, setLastNameFocus] = useState(false)
    const [codeFocus, setCodeFocus] = useState(false)
    const [phoneFocus, setPhoneFocus] = useState(false)
    const [emailFocus, setEmailFocus] = useState(false)
    
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const [error, setError] = useState(false);
    const [countryCodeOpen, setCountryCodeOpen] = useState(false);
    const [inputPhoneCode, setInputPhoneCode] = useState("+62");
    const [countryList, setCountryList] = useState([]);

    
    const handleSubmit = () => {
        if(inputPhoneCode.slice(1) != phoneCode) {
            setInputPhoneCode(`+${phoneCode}`)
        }
        if (salutation !== "" && firstName.length > 1 && phoneCode !== "" && phoneCode > 0 && phoneNo !== "" && phoneNo.length >= 8 && phoneNo > 0 && email != "" && emailRegex.test(email)) {
            setError(false);
            setContactError(false);
            let KAI = JSON.parse(localStorage.getItem("KAI"));
            KAI = KAI != null ? KAI : {};
            KAI.customerContactDetails = { salutation, firstName, lastName, phoneCode, phoneNo, email };
            localStorage.setItem("KAI", JSON.stringify(KAI));
            setOpenContactDetails(false)
        }
        else {
            setError(true);
        }
        setCountryCodeOpen(false)
    }
    // useEffect(() => {
    //     if(error) {
    //         setTimeout(() => {
    //             setError(false)
    //         }, 3000);
    //     }
    // }, [error]);

    useEffect(() => {
        if (submitError) {
            if (!salutation || salutation == "" || firstName.length < 2 || phoneCode == "" || phoneNo == "" || phoneNo.length < 8 || email == "") {
                setError(true);
                setOpenContactDetails(true);
            }
        }
    }, [submitError]);

    useEffect(() => {
        let KAI;
        let p = new Promise((resolve, reject) => {
            resolve(JSON.parse(localStorage.getItem("KAI")))
        })
        p.then(res => {
            if (res?.customerContactDetails) {
                setSalutation(res.customerContactDetails.salutation);
                setFirstName(res.customerContactDetails.firstName);
                setLastName(res.customerContactDetails.lastName)
                setPhoneCode(res.customerContactDetails.phoneCode);
                setPhoneNo(res.customerContactDetails.phoneNo);
                setEmail(res.customerContactDetails.email);
            }
            else console.log("contact details not found")
        })
    }, []);

    useEffect(() => {
        if(inputPhoneCode!="") {
            let actualPhoneCode = "";
            if(inputPhoneCode.slice(0,1) == "+") {
                actualPhoneCode=inputPhoneCode.slice(1)
            } else {
                actualPhoneCode=inputPhoneCode;
            }
            let sortedCountryList = [];
            for(let i=0; i<countryData.length; i++) {
                let isCountryName = countryData[i].name.toLowerCase().includes(actualPhoneCode.toLocaleLowerCase());
                let isCountryPhone = countryData[i].phone.includes(actualPhoneCode.toLocaleLowerCase());
                if(isCountryName || isCountryPhone) {
                    sortedCountryList.push(countryData[i])
                }
            }
            setCountryList([...sortedCountryList]);
            sortedCountryList = [];
        }
        if(inputPhoneCode=="" || inputPhoneCode=="+") {
            setCountryList([...countryData]);
            setPhoneCode("");
        }
    }, [inputPhoneCode]);

    return (
        <div id={"contact-details"} style={{ marginTop: "24px" }} >
            <Typography children={"Data Pemesan (Untuk E - Ticket)"} className={"inputSectionLabels"} />

            {/* FILL THE CONTACT DETAILS */}
            <Accordion expanded={openContactDetails} onChange={() => setOpenContactDetails(!openContactDetails)} id={"dataPemesan-dropdown"} style={{ marginTop: "16px" }} className={error || submitError ? "dropShadowError" : "dropShadow"} >
                <AccordionSummary >
                    {(salutation === '' && firstName === '' && lastName === '' || phoneCode == "" || phoneNo == "" || email == "") || (openContactDetails) ?
                        <Grid container>
                            <Grid item xs={9}> <Typography className={ error || submitError? "inputSecondarySectionLabelsError" : "inputSecondarySectionLabels"} >
                                Isi Data Pemesan
                                <span style={{ color: "red", fontSize: "bold" }} >*</span>
                            </Typography>
                            </Grid>
                            {!openContactDetails && JSON.parse(localStorage.getItem("KAI"))?.customerContactDetails == null &&
                                <Grid item style={{ left: '5%', position: 'relative' }}>
                                    <Grid container>
                                        <Grid item style={{ position: 'relative', top: '3px', marginRight: '3px' }}>  <EditIcon style={{ color: '#007DFE', fontSize: '15px' }} /> </Grid>
                                        <Grid item> <Typography children={"Isi Data"} style={{ fontSize: "14px", color: '#007DFE' }} /> </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        :
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <div >
                                <Typography children={firstName + ' ' + lastName} style={{ fontWeight: "600", fontSize: "12px", color: "#1A1A1A" }} />
                                <Typography children={email} style={{ fontSize: "12px", color: "#666", marginTop: "5px" }} />
                                <Typography children={`+${phoneCode} ${phoneNo}`} style={{ fontSize: "12px", color: "#666" }} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }} >
                                <CheckCircleIcon style={{ fontSize: "18px", marginRight: "2px", color: '#5CD182' }} />
                            </div>
                        </div>
                    }
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: "0" }} >
                    <Divider style={{ marginBottom: "16px" }} />
                    <FormControl variant="filled" fullWidth error={error && (salutation === ""|| !salutation)}>
                        <InputLabel id="demo-simple-select-label">Sapaan</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onFocus={()=>{setTitleFocus(true);setCountryCodeOpen(false);}}
                            onBlur={()=>setTitleFocus(false)}
                            style={{backgroundColor: '#F7F6F6'}}
                            value={salutation}
                            error={error && (salutation === "" || !salutation)}
                            label="Sapaan"
                            disableUnderline={((error && (salutation === "" || !salutation)) || titleFocus) ? false : true}
                            IconComponent={()=><KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />}
                            sx={{ fontWeight: 'bold' }}
                            onChange={(e) => {
                                if (e.target.value.length <= 25 && /^[a-zA-Z ]*$/.test(e.target.value))
                                    setSalutation(e.target.value)
                            }}
                        >
                            <MenuItem value={'MR'}>Tuan</MenuItem>
                            <MenuItem value={'MRS'}>Nyonya</MenuItem>
                            <MenuItem value={'MISS'}>Nona</MenuItem>
                        </Select>
                        <FormHelperText style={{ right: '15px', position: 'relative' }}>{((salutation === "" || !salutation) && error) ? "Sapaan tidak boleh kosong" : ""}</FormHelperText>
                    </FormControl>
                        {/* CONTACT PERSON NAME */}
                        <TextField id={"firstName-value"} fullWidth autoComplete={"off"}
                            label="Nama Depan"
                            value={firstName}
                            variant="filled"
                            error={error && firstName.length < 2}
                            onFocus={()=>{setFirstNameFocus(true);setCountryCodeOpen(false);}}
                            onBlur={()=>setFirstNameFocus(false)}
                            InputProps={{
                                disableUnderline: ((error && firstName.length < 2) || firstNameFocus) ? false : true,
                              }}
                            inputProps={{ style: { backgroundColor: '#F7F6F6', fontWeight: 'bold' } }}
                            sx={{ marginTop: '3%' }}
                            helperText={(firstName.length < 2  && error) ? "Nama depan tidak boleh kosong" : ""}
                            onChange={(e) => {
                                if (e.target.value.length <= 20 && /^[a-zA-Z ]*$/.test(e.target.value))
                                    setFirstName(e.target.value)
                            }
                            }
                            FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                        />
                        <TextField id={"lastName-value"} fullWidth autoComplete={"off"}
                            label="Nama Belakang"
                            value={lastName}
                            variant="filled"
                            onFocus={()=>{setLastNameFocus(true);setCountryCodeOpen(false);}}
                            onBlur={()=>setLastNameFocus(false)}
                            InputProps={{
                                disableUnderline: ((lastNameFocus) ? false : true),
                              }}
                            // error={error && lastName == ""}
                            inputProps={{ style: { backgroundColor: '#F7F6F6', fontWeight: 'bold', borderRadius: "8px" } }}
                            sx={{ marginTop: '3%' }}
                            onChange={(e) => {
                                if (e.target.value.length <= 20 && /^[a-zA-Z ]*$/.test(e.target.value))
                                    setLastName(e.target.value)
                            }
                            }
                            FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                        />
                        {/* PHONE INFO */}
                        <Grid container spacing={1}>
                            {/* PHONE CODE */}
                            <Grid item xs={5} >
                                {/* <Autocomplete
                                    id="country-select-phone"
                                    sx={{ marginTop: '7%', backgroundColor: '#F7F6F6', fontWeight: "bold" }}
                                    options={countryData}
                                    autoHighlight
                                    classes={classes}
                                    onChange={(_,v) => {
                                        setPhoneCode(v?.phone || "");
                                        setPhoneCodeOption(v || null);
                                     }}
                                    value={phoneCodeOption}

                                    getOptionLabel={(item) => `${item.name} (+${item.phone})`}
                                    renderOption={(props, item) => (
                                        <Box
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...props}

                                        >
                                        {item.name} (+{item.phone})
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Kode Negara"
                                            variant="filled"
                                            error={!phoneCode && error}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: "new-password" // disable autocomplete and autofill
                                            }}
                                            />
                                        )}
                                    /> */}
                                    <TextField variant="filled" 
                                        style={{ marginTop: '7%', fontWeight: "bold" }} 
                                        label="Kode Negara"
                                        error={!phoneCode && error} 
                                        inputProps={{ style: {  backgroundColor: '#F7F6F6', fontWeight: "bold"}}}
                                        InputProps={{
                                            disableUnderline: (((error && phoneCode === "")) ? false : true),
                                          }}
                                        autoComplete='off'
                                        onFocus={()=>setCountryCodeOpen(true)}
                                        value={inputPhoneCode}
                                        helperText={!phoneCode && error ? "Wajib diisi" : ""}
                                        onChange={(e)=>{
                                            setInputPhoneCode(e.target.value)}
                                        }
                                    />
                                    {countryCodeOpen && !titleFocus &&
                                    !firstNameFocus &&
                                    !lastNameFocus &&
                                    !phoneFocus &&
                                    !emailFocus
                                    && <div className='dropdownBox'>
                                        {
                                            countryList.map((item, i) => 
                                                <div key={i} className={"phoneCodeListItem"} onClick={()=>{setInputPhoneCode(`+${item.phone}`);setPhoneCode(item.phone);setCountryCodeOpen(false)}}>
                                                        <Typography type="text" children={`${item.name} (+${item.phone})`} />
                                                </div>
                                            )
                                        }
                                    </div>}
                                    {/* <FormHelperText style={{ right: '15px', color: "#c21616", position: 'relative' }}>{!phoneCode && error ? "Wajib diisi" : ""}</FormHelperText> */}

                            </Grid>
                            {/* PHONE NUMBER */}
                            <Grid item xs={7}>
                                <TextField autoComplete={"off"} fullWidth type={"number"} id={"handphone-value"}
                                    error={(error && (phoneNo < 0 || phoneNo == "" || phoneNo.length < 8)
                                        // (phoneNo.length!=10 && error)||
                                    )}
                                    value={phoneNo}
                                    variant="filled"
                                    label="Nomor Handphone"
                                    sx={{ marginTop: '5%' }}
                                    onFocus={()=>{setPhoneFocus(true); setCountryCodeOpen(false);}}
                                    onBlur={()=>setPhoneFocus(false)}
                                    onChange={(e) => { setPhoneNo(e.target.value) }}
                                    FormHelperTextProps={{ style: { color: "#c21616", display: error ? "" : "none" } }}
                                    helperText={((phoneNo == "" || phoneNo < 0 || phoneNo?.length < 8)/*||(error && phoneNo.length!=10)*/ && error) ? "Wajib diisi" : ""}
                                    InputProps={{ disableUnderline: ((error && (phoneNo < 0 || phoneNo == "" || phoneNo.length < 8)) || phoneFocus) ? false : true, style: { backgroundColor: '#F7F6F6', padding: '0px', fontWeight: 'bold' } }}
                                />
                            </Grid>
                        </Grid>

                        {/* ----EMAIL INFO---- */}
                        <TextField autoComplete={"off"} fullWidth id={"email"}
                            error={(error && email == "") || (error && !emailRegex.test(email))}
                            value={email}
                            variant="filled"
                            label={'Email'}
                            onFocus={()=>{setEmailFocus(true);setCountryCodeOpen(false);}}
                            onBlur={()=>setEmailFocus(false)}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{ disableUnderline: ((error && email == "") || (error && !emailRegex.test(email)) || emailFocus) ? false : true, style: { backgroundColor: '#F7F6F6', borderRadius: "8px", fontWeight: 'bold' } }}
                            sx={{ marginTop: '3%' }}
                            FormHelperTextProps={{ style: { margin: "0", color: "#c21616", display: error ? "" : "none" } }}
                            helperText={(email == "" && error) || (error && !emailRegex.test(email)) ? "Format email tidak sesuai" : ""}
                        />
                        <Typography children={"Contoh: email@example.com"} style={{ fontSize: "11px", color: "#979797" }} />

                        <Button children={"Simpan"} className={"submitButton"} fullWidth onClick={() => handleSubmit()} />
                    
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ContactDetails

const useStyle = makeStyles({
    inputRoot: {
        backgroundColor: "#f7f6f6",
      }
})