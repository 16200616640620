/* eslint-disable */
import React, { useState } from 'react';

import { IconButton, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { PopUpModal } from '../PopUp/PopUpModal';

const Header = ({header, back, close, step}) => {
    
    const [errorPopUp, showErrorPopUp] = useState({showPopUp: false});

    const handlePopUpClose = () => {
        showErrorPopUp({showPopUp: false});
    }
    
    return (
        <div id="header" className='header'>
            <PopUpModal open={errorPopUp.showPopUp} error={true} message={errorPopUp.message} handlePopUpClose={handlePopUpClose}/>
            <div style={{display:"flex", alignItems:"center", width: "100%"}}>
                {/* {
                    back!="" ? 
                    <Link to={back} ><ArrowBackRoundedIcon style={{color: '#615A5A', marginRight:"5px"}} /></Link>
                    : ""
                } */}
                <Typography style={{display:"flex", justifyContent:"center", width: "100%"}} id="headerText" className='headerText'>{header}</Typography>
            </div>
            {/* <IconButton 
                style={{display: close == false ? "none" : "" }} 
                onClick={()=> {}} 
            > 
                <CloseIcon style={{color:"#ADA6AE"}}/> 
            </IconButton> */}
        </div>
    )
}

export default Header
